import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import ContactConsultEt from "../../components/ContactConsultEt";

import LayoutEt from "../../components/LayoutEt"
import HeaderEt from '../../components/HeaderEt'
import Seo from "../../components/Seo"
import SolutionsSelectionEt from "../../components/SolutionsSelectionEt"
import PrefabFeaturesEt from "../../components/PrefabFeaturesEt"
import Angle from '../../images/icons/angle.svg'
import ContactConsult2Et from "../../components/ContactConsult2Et";

const Solutions = () => {
  return (
    <LayoutEt>

      <Seo title="Teenused" description="Puidust elementmajade tootja" lang="et" />
      <HeaderEt />

      <div className="container-base">
        <div className="section-1-2-center">
          <h1 className="title-xl wel-mb-s">Teenused</h1>
          <p className="body-l">Tehasemaja tellimine võib tunduda keeruline. Moodulmaja, elementmaja, tehasepakett ja prefab on ainult mõned terminid, millega arvatavasti kokku puutute. Lihtsustatult on küsimus selles, kui suur osa majast luuakse tehase tingimustes.</p></div>
      </div>

      <div className="mx-auto wel-mb-l px-8">
        <SolutionsSelectionEt />
      </div>

      <div id="maja" className="container-base">
        <div className="section-1-2-center">
          <p className="title-xs wel-mb-xs">Majaostjale</p>
          <h2 className="title-l wel-mb-s">Maja tellimine</h2>
          <p className="body-l">Iga kinnistu on natuke erinev. Aitame leida olemasolevate mudelite hulgast või koostöös arhitektiga teile sobiva lahenduse. </p>
        </div>
      </div>

      <div className="relative">
        <StaticImage
          src="../../images/majagrid.png"
          quality={100}
          formats={["auto", "webp", "avif"]}
          alt="Otsid maja?"
          loading="eager"
          placeholder="blurred"
          className="w-full"
        />
        <div className="hidden sm:block absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-welement-black text-welement-white p-16 rounded-lg text-center">
          <h3 className="title wel-mb-s">Otsid maja?</h3>
          <p className="body-l wel-mb-s">Leiame koos sinu vajadustele<br />
            sobivaima lahenduse</p>
          <Link to="/et/kontakt" className="btn btn-white">Võta ühendust</Link>
        </div>
      </div>

      <div className="container-base">
        <PrefabFeaturesEt />
      </div>

      <StaticImage
        src="../../images/maja-blue.png"
        quality={100}
        formats={["auto", "webp", "avif"]}
        alt="Maja"
        loading="eager"
        placeholder="blurred"
        className="w-full"
      />

      <ContactConsultEt />

      <div className="container-base text-center">
        <div className="section-1-2-center">
          <h2 className="title mb-16">Tarnitud Majad</h2>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          <div>
            <StaticImage
              src="../../images/portfolio/kloogaranna.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Maja"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded"
            />
          </div>

          <div>
            <StaticImage
              src="../../images/portfolio/hiiekula.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Maja"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded"
            />
          </div>

          <div>
            <StaticImage
              src="../../images/portfolio/tartu1.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Maja"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded"
            />
          </div>

          <div>
            <StaticImage
              src="../../images/portfolio/drobak.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Maja"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded"
            />
          </div>
        </div>

        <Link to="/et/portfoolio" className="btn btn-black">Veel Referentse</Link>
      </div>


      <div id="tootja" className="container-base">
        <div className="section-1-2-center">
          <p className="title-xs wel-mb-xs">Arendajale &amp; ehitajale</p>
          <h2 className="title-l wel-mb-s">Otsid majatootjat?</h2>
          <p className="body-l">Sul on juba käsil arendus- või ehitusprojekt? Platsitööd on peatselt algamas ja otsid usaldusväärset majatootjat, kes teostaks tööd kokkulepitud tähtajaks?</p>
        </div>
      </div>

      <StaticImage
        src="../../images/majatootja.png"
        quality={100}
        formats={["auto", "webp", "avif"]}
        alt="Majatootja"
        loading="eager"
        placeholder="blurred"
        className="w-full"
      />


      <div className="container-base text-center">
        <div className="section-1-2-center ">
          <h2 className="title wel-mb-l">Welementi eelised</h2>
        </div>

        <div className="grid md:grid-cols-3 gap-12 md:gap-8 w-full sm:w-2/3 md:w-full mx-auto wel-mb-l">
          <div className="text-center">
            <img src={Angle} alt="Icon" className="h-24 mx-auto wel-mb-s" />
            <h3 className="title-xs">Usaldusväärsus</h3>
            <p>Ehitusprojekti käigus võib palju muutuda. Suure ja lugupeetud ehituskontserni liikmena on Welement kindel partner. Keegi, kellele saad loota ka siis, kui olukord on keeruline. </p>
          </div>

          <div className="text-center">
            <img src={Angle} alt="Icon" className="h-24 mx-auto wel-mb-s" />
            <h3 className="title-xs">Innovaatilisus</h3>
            <p>Meie tehas on varustatud parima Saksa tootmistehnoloogiaga. Kõrge automatiseerituse tase tähendab kiirust, kuid ennekõike täpsust. </p>
          </div>

          <div className="text-center">
            <img src={Angle} alt="Icon" className="h-24 mx-auto wel-mb-s" />
            <h3 className="title-xs">Ehituskogemus</h3>
            <p>Meie meeskonna liikmed on suurte projektide juhtimise kogemusega. Me oleme istunud Sinu pool lauda ning teame, kui oluline on kokkulepetest kinnipidamine ja avatud kaartidega suhtlemine. </p>
          </div>
        </div>
        <Link to="/et/ettevottest" className="btn btn-black-outline mr-2">Meist</Link>
        <Link to="/et/tehasemaja" className="btn btn-black-outline">Tehasemajad</Link>
      </div>


      <div className="container-base text-center">
        <div className="section-1-2-center">
          <h2 className="title mb-16">Referentsid</h2>
        </div>
        <div className="grid grid-cols-1 w-2/3 mx-auto md:w-full md:grid-cols-2 gap-8">

          <div className="text-left mb-8">
            <StaticImage
              src="../../images/portfolio/oa.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Kaks ridaelamut ja üks kahekorruseline korterelamu. Welementi 
              meeskond projekteeris, tootis ja paigaldas moodulitest ridaelamud ja 
              elementlahendusena korterelamu."
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <h4 className="title-s wel-mb-xs">Oa tänava arendus</h4>
            <p className="wel-mb-xs">Kaks ridaelamut ja üks kahekorruseline korterelamu. Welementi 
meeskond projekteeris, tootis ja paigaldas moodulitest ridaelamud ja 
elementlahendusena korterelamu. </p>
            <p className="body-s uppercase">Moodulid</p>
          </div>

          <div className="text-left mb-8">
            <StaticImage
              src="../../images/portfolio/paju.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="16-korruseline büroohoone Tartu südalinnas.
              Welementi meeskond projekteeris, tootis ja paigaldas tehases valmistatud fassaadielemendid."
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <h4 className="title-s wel-mb-xs">Paju 2</h4>
            <p className="wel-mb-xs">16-korruseline büroohoone Tartu südalinnas.
              Welementi meeskond projekteeris, tootis ja paigaldas tehases valmistatud fassaadielemendid.	</p>
            <p className="body-s uppercase">Fassaadielemendid</p>
          </div>

          <div className="text-left mb-8">
            <StaticImage
              src="../../images/portfolio/hugo.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="6-korruseline hostel Tartu südalinnas.	Welementi meeskond projekteeris, tootis ja paigaldas tehases valmistatud fassaadielemendid."
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <h4 className="title-s wel-mb-xs">Hugo.Stay</h4>
            <p className="wel-mb-xs">6-korruseline hostel Tartu südalinnas.	Welementi meeskond projekteeris, tootis ja paigaldas tehases valmistatud fassaadielemendid.		</p>
            <p className="body-s uppercase">Fassaadielemendid</p>
          </div>

          <div className="text-left mb-8">
            <StaticImage
              src="../../images/portfolio/nupsik.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Ühekorruseline lasteaed Tartu äärelinnas.
              Welementi meeskond projekteeris, tootis ja paigaldas tehases valmistatud elemendid ja ristkihtpuidust detailid."
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <h4 className="title-s wel-mb-xs">Nupsiku Lasteaed	</h4>
            <p className="wel-mb-xs">Ühekorruseline lasteaed Tartu äärelinnas.
              Welementi meeskond projekteeris, tootis ja paigaldas tehases valmistatud elemendid ja ristkihtpuidust detailid. 	</p>
            <p className="body-s uppercase">Elementmaja</p>
          </div>
        </div>

        <Link to="/et/portfoolio" className="btn btn-black">Veel Referentse</Link>
      </div>

      <ContactConsult2Et />

    </LayoutEt>
  )

};

export default Solutions;