import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"



const ContactConsult2Et = () => {
  return (
    <div className="container-base">
      <div className="section-1-2-center">
        <div className="w-full bg-welement-pink p-12 rounded-lg text-center">
          <StaticImage
            src="../images/team/raiki.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Raiki"
            loading="eager"
            placeholder="blurred"
            className="w-24 rounded-full wel-mb-s"
          />
          <StaticImage
            src="../images/team/lauri.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Lauri"
            loading="eager"
            placeholder="blurred"
            className="w-24 mx-2 rounded-full wel-mb-s"
          />
          <StaticImage
            src="../images/team/roomet.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Roomet"
            loading="eager"
            placeholder="blurred"
            className="w-24 rounded-full wel-mb-s"
          />
          <p className="title-xs wel-mb-s">Leiame töötava lahenduse</p>
          <h4 className="title wel-mb-s">Võta ühendust</h4>
          <p className="body-l mb-8">Saada meile oma projekt! Vaatame koos joonised üle ja leiame parima lahenduse</p>
          <Link to="/et/kontakt" className="btn btn-red mr-2">Võta ühendust</Link>
        </div>
      </div>
    </div >
  )
}

export default ContactConsult2Et;
